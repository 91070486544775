@charset "UTF-8";
/* ----------------------------------------------------------------
フォームの設定
-----------------------------------------------------------------*/
.c-form {
	background-color: rgba(255,255,255,0.5);
	border: 1px solid #ddd;
	@media #{$querySp} {
		padding: 10px;
		@include borderRadius(3px);
	}
	@media #{$queryPc} {
		padding: 60px;
		@include borderRadius(5px);
	}
}

.c-formList {
	@media #{$querySp} {
		margin-bottom: 20px;
	}
	@media #{$queryPc} {
		margin-bottom: 33px;
	}
}

.c-formList__label {
	color: #000;
	@media #{$querySp} {
		margin-bottom: 3px;
		font-size: 1.2rem;
	}
	@media #{$queryPc} {
		margin-bottom: 5px;
		font-size: 1.6rem;
	}
}

/* 基本設定 */
.c-form input {
	&[type="text"], &[type="tel"], &[type="number"], &[type="email"], &[type="date"], &[type="month"], &[type="week"], &[type="time"], &[type="url"], &[type="password"] {
		border: 1px solid #ddd;
		background-color: #fff;
		width: 100%;
		@media #{$querySp} {
			@include borderRadius(3px);
			padding: 5px 10px;
			font-size: 1.2rem;
		}
		@media #{$queryPc} {
			@include borderRadius(5px);
			padding: 7px 20px;
		}
	}
}

/* フォーカス */
.c-form input {
	&[type="text"]:focus, &[type="tel"]:focus, &[type="number"]:focus, &[type="email"]:focus, &[type="date"]:focus, &[type="month"]:focus, &[type="week"]:focus, &[type="time"]:focus, &[type="url"]:focus, &[type="password"]:focus {
		box-shadow: 0 0 7px #38a3c6;
    	/*border: 1px solid #38a3c6;*/
    	@include trans($duration: .2s,$delay: 0s)
	}
}

.c-form textarea:focus {
	box-shadow: 0 0 7px #38a3c6;
    /*border: 1px solid #1b536a;*/
    @include trans($duration: .2s,$delay: 0s)
}

/* 郵便番号 */
.mwform-zip-field {
	display: block;
	width: 100%;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
	input {
		@media #{$querySp} {
			display: inline-block !important;
			width: 80px !important;
			margin: 0 5px;
		}
		@media #{$queryPc} {
			display: inline-block !important;
			width: auto !important;
			margin: 0 5px;
		}
		&:first-child {
			margin-left: 10px;
		}
	}
}

/* テキストエリア */
.c-form textarea {
	background-color: #fff;
	width: 100%;
	border: 1px solid #ddd;
	line-height: 1.5;
	@media #{$querySp} {
		padding: 5px 8px;
		min-height: 150px;
		@include borderRadius(3px);
		font-size: 1.2rem;
	}
	@media #{$queryPc} {
		min-height: 240px;
		padding: 10px 20px;
		@include borderRadius(5px);
	}
}

/* 送信ボタン */
.c-formList__button {
	position: relative;
	&:after {
		content: "";
		border: 0px;
		-ms-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		@include borderRadius(1px)
		border-top: solid 2px #fff;
		border-right: solid 2px #fff;
		@media #{$querySp} {
			width: 8px;
			height: 8px;
			right: 16px;
		}
		@media #{$queryPc} {
			width: 12px;
			height: 12px;
			right: 30px;
		}
	}
}
.c-form input[type="submit"] {
	width: 100%;
	color: #fff;
	text-align: center;
	background-color: #37a3c7;
	@include borderRadius(5px);
	&:hover {
		background-color: #7cc283;
		@include trans($duration: .2s,$delay: 0s)
	}
	@media #{$querySp} {
		padding: 14px;
		font-size: 1.4rem;
	}
	@media #{$queryPc} {
		padding: 21px;
		font-size: 1.8rem;
	}
}

.c-formError--input {
	input, textarea {
		border: 2px solid #e90303;
		background-color: #faf0f0;
	}
}