@charset "UTF-8";
/*----------------------------------------------------------------
グリッドのコンポーネント
-----------------------------------------------------------------*/
.c-grid {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: flex;
	}
}

.c-grid__col {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		margin-right: 24px;
	}
	&:last-child {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			margin-right: 0;
		}
	}
}

.c-grid--pc2 {
	.c-grid__col {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			width: 50%;
		}
	}
}