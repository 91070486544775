@charset "UTF-8";
/* ----------------------------------------------------------------
ABOUTページのみのスタイル
-----------------------------------------------------------------*/
.p-xxxxxxxx {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-aboutMainWrap {
	width: 100%;
	@media #{$querySp} {
		margin-bottom: 20px;
	}
	@media #{$queryPc} {
		margin: 50px 0 50px;
	}
}

.p-aboutMainInner {
	@media #{$querySp} {
		padding: 0 10px;
	}
	@media #{$queryPc} {
		width: 1140px;
		margin: 0 auto;
	}
}

.p-aboutMainBox {
	width: 100%;
	background-color: #fff;
	@media #{$querySp} {
		padding: 30px 10px 40px;
	}
	@media #{$queryPc} {
		padding: 70px 60px;
	}
}

.p-aboutMainText {
	@media #{$querySp} {
		margin-bottom: 20px;
	}
	@media #{$queryPc} {
		margin-bottom: 50px;
	}
}

.p-aboutMainPhoto {
	@media #{$querySp} {
		margin-bottom: 50px;
	}
	@media #{$queryPc} {
		margin-bottom: 100px;
	}
	img {
		@media #{$querySp} {
			width: 100%;
			height: auto;
		}
	}
}







.p-aboutMemberWrap {
	width: 100%;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-aboutMember__item {
	border-bottom: 1px solid #ddd;
	@media #{$querySp} {
		padding: 20px 0;
	}
	@media #{$queryPc} {
		padding: 40px 0;
		display: flex;
	}
	&:first-child {
		@media #{$querySp} {
			padding: 0px 0 20px;
		}
		@media #{$queryPc} {
			padding: 0px 0 40px;
		}
	}
	&:last-child {
		border-bottom: none;
		@media #{$querySp} {
			padding: 20px 0 0;
		}
		@media #{$queryPc} {
			padding: 40px 0 0;
		}
	}
}

.p-aboutMember__photo {
	@media #{$querySp} {
		float: left;
		margin: 0 10px 0 0;
	}
	@media #{$queryPc} {
		margin-right: 40px;
	}
	img {
		@media #{$querySp} {
			width: 80px;
			height: auto;
		}
	}
}

.p-aboutMember__textName {
	display: block;
	color: #000;
	font-family: $fontJpHiraMin;
	letter-spacing: 3px;
	@media #{$querySp} {
		margin-bottom: 4px;
		font-size: 1.4rem;
	}
	@media #{$queryPc} {
		margin-bottom: 5px;
		font-size: 1.8rem;
	}
}

.p-aboutMember__text--url {
	display: block;
	margin-top: 8px;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
	a {
		color: #38a3c6;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
		&:hover {
			color: #55b0aa;
			@include trans($duration: .1s,$delay: 0s);
			@media #{$querySp} {
			}
			@media #{$queryPc} {
			}
		}
	}
}









.p-aboutInfoWrap {
	width: 100%;
	@media #{$querySp} {
		margin-bottom: 20px;
	}
	@media #{$queryPc} {
		margin-bottom: 50px;
	}
}

.p-aboutInfo__bg {
	width: 100%;
	@media #{$querySp} {
		height: 150px;
		background: url("../images/bg_photo_about.jpg") no-repeat center top;
		background-size: cover;
	}
	@media #{$queryPc} {
		height: 298px;
		background: url("../images/bg_photo_about.jpg") no-repeat center top;
		background-size: cover;
	}
}

.p-aboutInfoInner {
	@media #{$querySp} {
		padding: 0 10px;
	}
	@media #{$queryPc} {
		width: 1140px;
		margin: 0 auto;
	}
}

.p-aboutInfoBox {
	width: 100%;
	background-color: #fff;
	@media #{$querySp} {
		margin-top: -50px;
		padding: 30px 10px 40px;
	}
	@media #{$queryPc} {
		margin-top: -100px;
		padding: 70px 60px;
	}
}







.p-aboutAdd {
	width: 100%;
	@media #{$querySp} {
		margin-bottom: 20px;
	}
	@media #{$queryPc} {
		margin-bottom: 50px;
	}
}

.p-aboutAdd__item {
	border-top: 1px solid #ddd;
	@media #{$querySp} {
		padding: 8px 0;
	}
	@media #{$queryPc} {
		padding: 16px 0;
	}
	&:last-child {
		border-bottom: 1px solid #ddd;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
}

.p-aboutMap {
	border: 1px solid #ddd;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}


















