@charset "UTF-8";
/* ----------------------------------------------------------------
定義リストのコンポーネント
-----------------------------------------------------------------*/
.c-dfBox {
	width: 100%;
	background-image: linear-gradient(to right, #C6C5C5, #C6C5C5 1px, transparent 1px, transparent 3px);
	background-size: 4px 1px;
	background-position: bottom;
	background-repeat: repeat-x;
	padding-bottom: 1px;
	@media #{$querySp} {
		margin: 16px 0;
	}
	@media #{$queryPc} {
		margin: 24px 0;
	}
}

.c-dfBox--bgOdd {
	& .c-def:nth-child(odd) {
		background-color: #fcfbfa;
	}
}

.c-def {
	width: 100%;
	background-image: linear-gradient(to right, #C6C5C5, #C6C5C5 1px, transparent 1px, transparent 3px);
	background-size: 4px 1px;
	background-position: top;
	background-repeat: repeat-x;
	dt {
		font-weight: 600;
		color: $clrBlck;
	}
	@media #{$querySp} {
		padding: 12px 15px;
		dt {
			margin-bottom: 4px;
		}
	}
	@media #{$queryPc} {
		display: flex;
		dt {
			padding: 15px 20px;
		}
		dd {
			padding: 15px 0;
		}
	}
}

.c-def--bgGray {
	background-color: #fcfbfa;
}

.c-def--bgWhite {
	background-color: #fff;
}