@charset "UTF-8";
/*----------------------------------------------------------------
パンくずのコンポーネント
-----------------------------------------------------------------*/
.c-breadcrumb {
	width: 100%;
	background-color: #fafafa;
	@media #{$querySp} {
		display: none;
	}
}

.c-breadcrumbBody {
	width: 1140px;
	margin: 0 auto;
	padding: 8px 0;
}

.c-breadcrumb__item {
	display: inline-block;
	margin-right: 10px;
	font-size: 1.1rem;
	line-height: 1;
	a {
		color: $clrBlck;
		&:hover {
			@include opacity(0.7)
			@include trans($duration: .1s,$delay: 0s)
		}
	}
	&:first-child {
		padding-left: 16px;
		a {
			width: 16px;
			position: relative;
			span {
				display: none;
			}
			&:before {
				content: "";
				display: block;
				width: 16px;
				height: 14px;
				background: url("../images/ico_bread_home.png") no-repeat left center;
				position: absolute;
				top: -2px;
				left: -16px;
			}
		}
	}
	&:not(:last-child) {
		&:after {
			content: "";
			display: inline-block;
			font-family: FontAwesome;
			content: "\f105";
			padding-left: 11px;
			color: #ccc;
		}
	}
}

//modifier
.c-breadcrumb__item--home {
	img {
		margin-top: -3px;
	}
}

.c-breadcrumb__item--active {
	a {
		color: #9b9b9b;
	}
}