@charset "UTF-8";
/* ----------------------------------------------------------------
MISSIONページのみのスタイル
-----------------------------------------------------------------*/
.p-xxxxxxxx {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-missionWrap {
	width: 100%;
	position: relative;
	background: url("../images/bg_missionHead.png") no-repeat center top;
	background-size: cover;
	@media #{$querySp} {
		padding: 32px 10px 20px;
	}
	@media #{$queryPc} {
		padding: 80px 0 40px;
	}
}

.p-mission_headCopy {
	color: #000;
	font-family: $fontJpHiraMin;
	text-align: center;
	line-height: 1;
	@media #{$querySp} {
		margin-bottom: 20px;
		font-size: 1.5rem;
	}
	@media #{$queryPc} {
		margin-bottom: 40px;
		font-size: 3.0rem;
	}
}

.p-mission_headText {
	color: #000;
	text-align: center;
	@media #{$querySp} {
		margin-bottom: 20px;
		line-height: 2.5;
	}
	@media #{$queryPc} {
		margin-bottom: 40px;
		line-height: 2.5;
	}
}









.p-MissionMessaWrap {
	width: 100%;
	@media #{$querySp} {
		margin-bottom: 20px;
	}
	@media #{$queryPc} {
		margin-bottom: 50px;
	}
}

.p-MissionMessa__bg {
	width: 100%;
	@media #{$querySp} {
		height: 150px;
		background: url("../images/bg_photo_message.jpg") no-repeat center top;
		background-size: cover;
	}
	@media #{$queryPc} {
		height: 298px;
		background: url("../images/bg_photo_message.jpg") no-repeat center top;
		background-size: cover;
	}
}

.p-MissionMessaInner {
	@media #{$querySp} {
		padding: 0 10px;
	}
	@media #{$queryPc} {
		width: 1140px;
		margin: 0 auto;
	}
}

.p-MissionMessaBox {
	width: 100%;
	background-color: #fff;
	@media #{$querySp} {
		margin-top: -50px;
		padding: 30px 10px 40px;
	}
	@media #{$queryPc} {
		margin-top: -100px;
		padding: 70px 60px;
	}
}

.p-MissionMessaCont {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: flex;
	}
}

.p-MissionMessa__photo {
	@media #{$querySp} {
		float: left;
		margin: 0 10px 10px;
	}
	@media #{$queryPc} {
		margin-right: 80px;
	}
	img {
		@media #{$querySp} {
			width: 100px;
			height: auto;
		}
	}
}

.p-MissionMessa__text {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}






.p-xxxxxxxx {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-MissionAreaWrap {
	width: 100%;
	@include gradient(#7cc283, #37a3c7, horizontal);
	color: #fff;
	@media #{$querySp} {
		margin-bottom: 20px;
		padding: 30px 10px 10px;
	}
	@media #{$queryPc} {
		padding: 72px 0 80px;
		margin-bottom: 50px;
	}
}

.p-MissionAreaInner {
	@media #{$querySp} {
		width: 100%;
	}
	@media #{$queryPc} {
		width: 1020px;
		margin: 0 auto;
	}
}

.p-homeHeadline--area {
	color: #fff;
}

.p-MissionAreaBox {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: flex;
		justify-content: space-between;
	}
}

.p-MissionArea__item {
	@media #{$querySp} {
		width: 100%;
	}
	@media #{$queryPc} {
		width: 314px;
	}
}

.p-MissionArea__headline {
	position: relative;
	font-family: $fontEnCinzel;
	line-height: 1;
	@media #{$querySp} {
		padding-left: 28px;
		margin-bottom: 16px;
		font-size: 2.0rem;
	}
	@media #{$queryPc} {
		margin-bottom: 32px;
		padding-left: 50px;
		font-size: 3.4rem;
	}
}

.p-MissionArea__headlineNum {
	position: absolute;
	left: 0;
	font-family: $fontEnCinzel;
	@media #{$querySp} {
		top: -2px;
		font-size: 4rem;
	}
	@media #{$queryPc} {
		top: -15px;
		font-size: 7.7rem;
	}
}

.p-MissionArea__headlineNum--01 {
	@media #{$querySp} {
		left: 4px;
	}
	@media #{$queryPc} {
	}
}

.p-MissionArea__headlineJp {
	display: block;
	font-family: $fontJpHiraMin;
	@media #{$querySp} {
		margin-top: 4px;
		font-size: 1.5rem;
	}
	@media #{$queryPc} {
		margin-top: 4px;
		font-size: 1.8rem;
	}
}

.p-MissionArea__text {
	@media #{$querySp} {
		margin-bottom: 40px;
		padding-left: 28px;
	}
	@media #{$queryPc} {
	}
}




.p-xxxxxxxx {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-missionPartnerWrap {
	width: 100%;
	background-color: #fafafa;
	@media #{$querySp} {
		padding: 0 10px;
	}
	@media #{$queryPc} {
		margin-bottom: 50px;
	}
}

.p-missionPartnerInner {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		width: 1140px;
		margin: 0 auto;
	}
}

.p-missionPartnerBox {
	width: 100%;
	background-color: #fff;
	@media #{$querySp} {
		padding: 32px 10px 40px;
	}
	@media #{$queryPc} {
		padding: 72px 60px 80px;
	}
}

.p-missionPartnerCont {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: flex;
		align-items: center;
	}
}

.p-missionPartnerCont--last {
	@media #{$querySp} {
		margin-top: 20px;
	}
	@media #{$queryPc} {
		margin-top: 80px;
	}
}

.p-missionPartner__photo {
	@media #{$querySp} {
		margin-bottom: 10px;
	}
	@media #{$queryPc} {
		margin: 0 50px 0 -90px;
	}
	img {
		@media #{$querySp} {
			width: 100%;
			height: auto;
		}
	}
}

.p-missionPartner__text {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
	h4 {
		font-family: $fontJpHiraMin;
		font-weight: 600;
		color: #000;
		@media #{$querySp} {
			margin-bottom: 6px;
			font-size: 1.2em;
		}
		@media #{$queryPc} {
			margin-bottom: 12px;
			font-size: 2.0rem;
		}
	}
}












































