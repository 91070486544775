@charset "UTF-8";
/* ----------------------------------------------------------------
テーブルのコンポーネント
-----------------------------------------------------------------*/
.c-tableWrap {
	background-color: #f4f4f4;
	@media #{$querySp} {
		padding: 10px;
	}
	@media #{$queryPc} {
		padding: 40px;
	}
}

.c-tableHeadline {
	font-weight: 600;
	text-align: center;
	color: #000;
	@media #{$querySp} {
		margin-bottom: 8px;
	}
	@media #{$queryPc} {
		font-size: 1.8rem;
		margin-bottom: 20px;
	}
}

.c-table {
    width: 100%;
    @media #{$querySp} {
    	border: 1px solid #e6e6e6;
    	border-bottom: none;
    	margin-bottom: 20px;
	}
	@media #{$queryPc} {
		border: 1px solid #e6e6e6;
		margin-bottom: 40px;
	}
}

.c-table tbody tr {

	@media #{$querySp} {
	}
	@media #{$queryPc} {
		border-bottom: 1px solid #e6e6e6;
	}
}

.c-table tbody tr  {
	@media #{$querySp} {
		border-bottom: 1px solid #e6e6e6;
	}
	@media #{$queryPc} {
	}
	& th , td {
		@media #{$querySp} {
			padding: 8px 10px;
		}
		@media #{$queryPc} {
			padding: 14px 20px;
		}
	}
}

.c-table tbody tr th {
	color: #000;
	font-weight: 600;
	background-color: #fafafa;
	@media #{$querySp} {
		display: block;
		width: 100%;
		border-bottom: 1px solid #e6e6e6;
	}
	@media #{$queryPc} {
		border-right: 1px solid #e6e6e6;
	}
}

.c-table tbody tr td {
	background-color: #fff;
	@media #{$querySp} {
		display: block;
		width: 100%;
		padding: 4px 10px;
	}
	@media #{$queryPc} {
		border-right: 1px solid #e6e6e6;
	}
}

.c-table--thCenter tbody tr th {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		text-align: center;
	}
}