@charset "UTF-8";
/*----------------------------------------------------------------
見出しのコンポーネント
-----------------------------------------------------------------*/
.c-headline01,
.c-headline02,
.c-headline03,
.c-headline04,
.c-headline05,
.c-headline06 {
	color: $clrBlck;
	line-height: 1.4;
	small {
	}
}

.c-headline__sub {
	text-align: center;
	font-weight: 600;
	@media #{$querySp} {
		font-size: 1.3rem;
	}
	@media #{$queryPc} {
		line-height: 1.8;
		font-size: 1.6rem;
		color: #555;
	}
}

.c-headline01 {
	font-family: $fontEnCinzel;
	display: inline-block;
	position: relative;
	color: #fff;
	@media #{$querySp} {
		font-size: 1.6rem;
	}
	@media #{$queryPc} {
		font-size: 2.3rem;
		letter-spacing: 3px;
	}
	span {
		display: block;
		display: none;
		@media #{$querySp} {
			font-family: $fontEnOswald;
			font-weight: 400;
			font-size: 1.0rem;
		}
		@media #{$queryPc} {
			font-family: $fontEnOswald;
			font-weight: 300;
			font-size: 1.6rem;
		}
	}
}

.c-headline02 {
	position: relative;
	text-align: center;
	@media #{$querySp} {
		margin-bottom: 24px;
	}
	@media #{$queryPc} {
		margin-bottom: 48px;
	}
	&--jp {
		position: relative;
		display: inline-block;
		background-color: #fff;
		z-index: 1;
		@media #{$querySp} {
			padding: 0 12px;
			font-size: 1.6rem;
		}
		@media #{$queryPc} {
			padding: 0 24px;
			font-size: 3.0rem;
		}
	}
	&--en {
		display: block;
		font-family: $fontEnOswald;
		font-weight: 300;
		@include gradient(#37a8b1, #1788c4, horizontal);
  		-webkit-background-clip: text;
  		-webkit-text-fill-color: transparent;
		@media #{$querySp} {
			font-size: 1.2rem;
		}
		@media #{$queryPc} {
			font-size: 2.4rem;
		}
	}
	&:before {
		content: "";
		width: 100%;
		height: 1px;
		background-color: #e6e6e6;
		position: absolute;
		left: 0;
		@media #{$querySp} {
			top: 12px;
		}
		@media #{$queryPc} {
			top: 22px;
		}
	}
}

@media all and (-ms-high-contrast:none){
	*::-ms-backdrop,
	.c-headline02 .c-headline02--en {
		background: none;
		color: #1683be;
	}
}

.c-headline02--about {
	.c-headline02--jp {
		background-color: #fafafa;
	}
}

.c-headline03 {
	position: relative;
	@media #{$querySp} {
		margin-bottom: 8px;
		font-size: 1.3rem;
	}
	@media #{$queryPc} {
		margin-bottom: 16px;
		font-size: 2.4rem;
	}
	&:before {
		position: absolute;
		content: "";
		@include gradient(#359cbe, #1b5ea6, horizontal);
		@media #{$querySp} {
			top: -8px;
			left: 1px;
			width: 25px;
			height: 1px;
		}
		@media #{$queryPc} {
			top: -13px;
			left: 1px;
			width: 68px;
			height: 1px;
		}
	}
}

.c-headline04 {
	@media #{$querySp} {
		margin-bottom: 8px;
		font-size: 1.3rem;
	}
	@media #{$queryPc} {
		font-size: 1.8rem;
	}
}