@charset "UTF-8";
/* ----------------------------------------------------------------
CONTACTページのみのスタイル
-----------------------------------------------------------------*/
.p-contactAdd {
	font-weight: 600;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		font-size: 1.8rem;
	}
	&:hover {
		@include opacity(0.7)
		@include trans($duration: .1s,$delay: 0s)
	}
}

.p-formWrap {
	@media #{$querySp} {
		padding: 10px;
	}
	@media #{$queryPc} {
		padding: 0 40px;
	}
}

.p-formText__s {
	@media #{$querySp} {
		font-size: 1.0rem;
	}
	@media #{$queryPc} {
		font-size: 1.2rem;
	}
}

.p-formAtt {
	color: #e90303;
	@media #{$querySp} {
		margin: 0 0 10px;
		font-size: 1.0rem;
	}
	@media #{$queryPc} {
		margin: 0 0 10px;
		font-size: 1.2rem;
	}
}

.p-fromReq {
	color: #e90303;
	display: inline-block;
	margin-left: 5px;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-formError--text {
	color: #e90303;
	@media #{$querySp} {
		font-size: 1.0rem;
	}
	@media #{$queryPc} {
		font-size: 1.2rem;
	}
}

.p-contactBtnTop {
	text-align: center;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-contactBtnTop__link {
	position: relative;
	display: inline-block;
	@include gradient(#7cc283, #37a3c7, horizontal);
	color: #fff;
	@media #{$querySp} {
		width: 50%;
		height: 40px;
		line-height: 40px;
		@include borderRadius(3px);
	}
	@media #{$queryPc} {
		width: 222px;
		height: 60px;
		line-height: 60px;
		@include borderRadius(5px);
	}
	&:hover {
		color: #fff;
		@include opacity(0.7)
		@include trans($duration: .2s,$delay: 0s)
	}
	&:after {
		content: "";
		border: 0px;
		-ms-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		@include borderRadius(1px)
		border-top: solid 2px #fff;
		border-right: solid 2px #fff;
		@media #{$querySp} {
			width: 8px;
			height: 8px;
			right: 16px;
		}
		@media #{$queryPc} {
			width: 8px;
			height: 8px;
			right: 16px;
		}
	}
}