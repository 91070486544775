@charset "UTF-8";
/* ----------------------------------------------------------------
TOPページのみのスタイル
-----------------------------------------------------------------*/
/*	kv
______________________________________________*/
.p-homeKvWrap {
	position: relative;
	z-index: 1;
	width: 100%;
	@media #{$querySp} {
		height: 200px;
	}
	@media #{$queryPc} {
		height: 750px;
	}
}

.p-homeKv {
	position: relative;
	width: 100%;
	@media #{$querySp} {
		height: 200px;
	}
	@media #{$queryPc} {
		height: 750px;
	}
}

.p-homeKv__item {
	width: 100%;
	@media #{$querySp} {
		height: 200px;
	}
	@media #{$queryPc} {
		height: 750px;
	}
	&:nth-child(1) {
		background: url("../images/photo_kv01.jpg") no-repeat center top;
		background-size: cover;
		img {
			@media #{$querySp} {
				width: 257px;
				height: auto;
			}
		}
	}
	&:nth-child(2) {
		background: url("../images/photo_kv02.jpg") no-repeat center top;
		background-size: cover;
		img {
			@media #{$querySp} {
				width: 320px;
				height: auto;
			}
		}
	}
	&:nth-child(3) {
		background: url("../images/photo_kv03.jpg") no-repeat center top;
		background-size: cover;
		img {
			@media #{$querySp} {
				width: 255px;
				height: auto;
			}
		}
	}
	img {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
	}
}


/*
.p-homeCopy__jp {
	display: inline-block;
	width: 100%;
	text-align: center;
	position: absolute;
	top: 50%;
  	transform: translateY(-50%);
	text-shadow:0px 0px 45px #000;
	line-height: 1.5;
	color: #fff;
	font-family: $fontJpHiraMin;
	@media #{$querySp} {
		font-size: 2.2rem;
		letter-spacing: 3px;
	}
	@media #{$queryPc} {
		font-size: 5.8rem;
		letter-spacing: 16px;
	}
}
*/

/*	News
______________________________________________*/
.p-homeNewsWrap {
	width: 100%;
	position: relative;
	background-color: #fff;
	@media #{$querySp} {
		padding: 32px 10px 40px;
	}
	@media #{$queryPc} {
		padding: 64px 0 80px;
	}
}

.p-homeNewsInner {
	position: relative;
	@media #{$querySp} {
		width: 100%;
	}
	@media #{$queryPc} {
		width: 1260px;
		margin: 0 auto;
	}
}

/*	Member
______________________________________________*/
.p-homeMissionWrap {
	width: 100%;
	position: relative;
	background: url("../images/bg_missionHead.png") no-repeat center top;
	background-size: cover;
	@media #{$querySp} {
		padding: 32px 10px 40px;
	}
	@media #{$queryPc} {
		padding: 64px 0 80px;
	}
}

.p-homeMissionInner {
	@media #{$querySp} {
		width: 100%;
	}
	@media #{$queryPc} {
		width: 1260px;
		margin: 0 auto;
	}
}

/*	Portfolio
______________________________________________*/
.p-homePfWrap {
	width: 100%;
	position: relative;
	background-color: #fff;
	@media #{$querySp} {
		padding: 32px 10px 40px;
	}
	@media #{$queryPc} {
		padding: 64px 0 80px;
	}
}

.p-homePfInner {
	@media #{$querySp} {
		width: 100%;
	}
	@media #{$queryPc} {
		width: 1260px;
		margin: 0 auto;
	}
}

.p-homePfItemWrap {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
}

.p-homePfItem {
	@media #{$querySp} {
		width: 100%;
		margin-bottom: 20px;
	}
	@media #{$queryPc} {
		width: 388px;
	}
	&:last-child {
		margin-bottom: 0;
	}
}

.p-homePfItem__photo {
	@media #{$querySp} {
		width: 100%;
		height: auto;
		margin-bottom: 10px;
	}
	@media #{$queryPc} {
		margin-bottom: 20px;
	}
}

/*	Service
______________________________________________*/
.p-homeSvWrap {
	width: 100%;
	background-color: #fafafa;
	@media #{$querySp} {
		padding: 0px 10px 10px;
	}
	@media #{$queryPc} {
		padding: 50px 0;
	}
}

.p-homeSvInner {
	position: relative;
	text-align: right;
	@media #{$querySp} {
		width: 100%;
	}
	@media #{$queryPc} {
		width: 1260px;
		margin: 0 auto;
	}
}

.p-homeSvPic {
	@media #{$querySp} {
		display: none;
	}
	@media #{$queryPc} {
		position: absolute;
		top: 20px;
		left: -25px;
	}
}

.p-homeSvBox {
	display: inline-block;
	@media #{$querySp} {
		position: relative;
		z-index: 1;
		background-color: rgba(255,255,255,.8);
		background: url("../images/pic_homeSv_sp.png") no-repeat center center;
		background-size: cover;
	}
	@media #{$queryPc} {
		background-color: #fff;
		width: 748px;
		padding: 60px 60px 60px 135px;
	}
}

.p-homeSvBoxInner {
	@media #{$querySp} {
		padding: 40px 10px;
		background-color: rgba(255,255,255,.8);
	}
	@media #{$queryPc} {
	}
}

.p-homeSvHead {
	text-align: left;
	font-family: $fontJpHiraMin;
	font-weight: 600;
	color: #000;
	@media #{$querySp} {
		margin-bottom: 4px;
		font-size: 1.4rem;
	}
	@media #{$queryPc} {
		margin-bottom: 4px;
		font-size: 2.0rem;
	}
}

.p-homeSvText {
	text-align: left;
	line-height: 2.1;
	@media #{$querySp} {
		margin-bottom: 20px;
	}
	@media #{$queryPc} {
		margin-bottom: 48px;
	}
}

/*	トップ共通パーツ
______________________________________________*/
.p-xxxxxxxx {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-homeHeadline {
	font-family: $fontEnCinzel;
	color: #000;
	text-align: center;
	letter-spacing: 3px;
	@media #{$querySp} {
		margin-bottom: 24px;
		font-size: 1.4em;
	}
	@media #{$queryPc} {
		margin-bottom: 56px;
		font-size: 2.3rem;
	}
}

.p-homeHeadline--lower {
	font-family: $fontJpYuMin;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-homeBtnMore {
	font-family: $fontEnCinzel;
	text-align: center;
	color: 000;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
	a {
		position: relative;
		display: inline-block;
		@media #{$querySp} {
			width: 56px;
		}
		@media #{$queryPc} {
			width: 66px;
		}
		&:before {
			content: "";
			position: absolute;
			left: 3px;
			background-color: #4bacb3;
			@media #{$querySp} {
				bottom: -3px;
				width: 56px;
				height: 1px;
			}
			@media #{$queryPc} {
				bottom: 0;
				width: 66px;
				height: 1px;
			}
		}
		&:after {
			content: "";
			position: absolute;
			background-color: #4bacb3;
			@include rotate(30deg);
			@media #{$querySp} {
				right: -4px;
				bottom: -1px;
				width: 10px;
				height: 1px;
			}
			@media #{$queryPc} {
				right: -3px;
				bottom: 3px;
				width: 12px;
				height: 1px;
			}
		}
		&:hover {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
				@include opacity(0.7)
				@include trans($duration: .2s,$delay: 0s)
			}
		}
	}
}

.p-homeBtnMore--news {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		position: absolute;
		top: 10px;
		right: 0;
	}
}






































































