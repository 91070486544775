@charset "UTF-8";
/*----------------------------------------------------------------
サブコンテンツのコンポーネント
-----------------------------------------------------------------*/
.c-subNavHead {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: table;
		width: 275px;
		height: 140px;
		margin-top: -200px;
		background-color: $clrAccent;
	}
}

.c-subNavHead__item {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		font-size: 1.8rem;
		font-weight: 600;
		color: #fff;
		line-height: 1.4;
	}
}

.c-subNavHead__item--en {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: block;
		margin-top: 12px;
		font-size: 1.2rem;
		color: $clrMain;
	}
}

.c-subNav {
	background-color: #fff;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		padding: 20px;
		font-size: 1.4rem;
	}
}

.c-subNav__item {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		border-bottom: 1px solid $clrBorder;
	}
}

.c-subNav__item {
	&:last-child {
		border-bottom: none;
	}
}

.c-menuItem__link {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: block;
		padding: 11px 16px;
	}
	&:last-child {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			border-bottom: none;
		}
	}
}

.c-subNav3thItem {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		border-bottom: 1px solid $clrBorder;
	}
}

.c-subNav3thItem {
	&:first-child {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			border-top: 1px solid $clrBorder;
		}
	}
	&:last-child {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			border-bottom: none;
		}
	}
}

.c-subNav3thItem__link {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: block;
		padding: 11px 16px;
	}
}

.c-subNav3thItem--active {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		background-color: #f1f6f7;
	}
}