@charset "UTF-8";
/* ----------------------------------------------------------------
base
-----------------------------------------------------------------*/

//base setting
html {
	font-size: 62.5%;
    width: 100%;
    height: 100%;
	-webkit-text-size-adjust: none;
}

body {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	font-size: $fsBase + rem;
	font-family: $fontJpYuGo;
	font-weight: normal;
	line-height: $lhBase;
	color: #555555;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	@media #{$querySp} {
		font-size: $fsBaseSp + rem;
		line-height: $lhBaseSp;
	}
	@media #{$queryPc} {
		min-width: 1200px;
	}
}

@media all and (-ms-high-contrast:none) {
	body {
		font-family: $fontJpMeiryo;
	}
}

a {
	color: $clrLink;
	text-decoration: none;
    cursor: pointer;
	&:link,&:visited,&:hover,&:active {
		outline: none;
	}
	&:hover {
		color: $clrLinkHover;
	}
}

img {
	vertical-align: middle;
}