@charset "UTF-8";
/*----------------------------------------------------------------
リストのコンポーネント
・ドットリスト
・番号リスト
・チェックリスト
・アルファベットリスト
-----------------------------------------------------------------*/
.c-list,
.c-listOreder,
.c-listCheck {
	@media #{$querySp} {
		margin: 16px 0;
		line-height: 1.3;
	}
	@media #{$queryPc} {
		margin: 24px 0;
		font-size: 1.4rem;
		line-height: 1.5;
	}
}

.c-list > li,
.c-listOreder > li,
.c-listCheck > li {
	padding-left: 16px;
	line-height: 1.5;
	margin-bottom: 12px;
}

//ドットリスト
.c-list > li {
	position: relative;
	&:before {
		display: block;
		content: '';
		position: absolute;
		top: 0.5em;
		left: 1px;
		width: 4px;
		height: 4px;
		@include gradient(#359cbe, #1b5ea6, horizontal);
		border-radius: 100%;
	}
}