@charset "UTF-8";
/* ----------------------------------------------------------------
headerのスタイル
-----------------------------------------------------------------*/
.h-wrap {
	background-color: #fff;
	@media #{$querySp} {
		border-bottom: 1px solid #dedede;
	}
	@media #{$queryPc} {
		position: relative;
		width: 100%;
		line-height: 1;
	}
}

.h-inner {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		width: 100%;
		display: flex;
		align-items: center;
	}
}

.h-logo {
	@media #{$querySp} {
		img {
			width: 120px;
			height: auto;
			margin-top: -6px;
		}
	}
	@media #{$queryPc} {
		width: 390px;
		h1 {
			margin-top: -7px;
			padding: 0 0 0 80px;
		}
	}
}

.h-nav {
	@media #{$querySp} {
		padding: 20px;
	}
	@media #{$queryPc} {
		display: table;
		float: right;
	}
}

.h-navItem {
	@media #{$querySp} {
		border: 1px solid #fff;
		border-bottom: none;
	}
	@media #{$queryPc} {
		display: table-cell;
		padding: 3px 64px 0 0;
		vertical-align: middle;
	}
	&:last-child {
		@media #{$querySp} {
			border: 1px solid #fff;
		}
		@media #{$queryPc} {
			padding: 0;
		}
		.h-navItem__link {
			@media #{$queryPc} {
				display: block;
				width: 222px;
				height: 80px;
				@include gradient(#7cc283, #37a3c7, horizontal);
				text-align: center;
				color: #fff;
				line-height: 80px;
			}
		}
	}
}

.h-navItem__link {
	@media #{$querySp} {
		display: block;
		padding: 16px;
		color: #fff;
		span {
			position: relative;
			display: block;
			&:after {
				font-family: FontAwesome;
				content : "\f105";
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				margin: auto 0;
				color: #fff;
			}
		}
		&:hover {
			color: #fff;
		}
	}
	@media #{$queryPc} {
		display: block;
		font-size: 1.4rem;
		&:hover {
			@include opacity(0.7)
			@include trans($duration: .2s,$delay: 0s)
		}
	}
}

.h-navItem__link span .h-navItem__link--en {
	font-family: $fontJpHiraMin;
	@media #{$querySp} {
		display: inline;
		margin-left: 7px;
		font-size: 1.0rem;
		display: none;
		&:after {
			content: "/";
			top: -3px;
			left: -6px;
			display: none;
		}
	}
	@media #{$queryPc} {
		text-align: center;
		display: block;
		margin-top: 4px;
		font-weight: normal;
		font-size: 1.1rem;
		color: #929292;
		display: none;
	}
}

/*	SP版ヘッダー調整
______________________________________________*/
.h-spHeadWrap {
	@media #{$querySp} {
		position: relative;
		z-index: 1;
		background-color: #fff;
		padding: 8px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	@media #{$queryPc} {
	}
}


.h-navBtn__open {
	@media #{$queryPc} {
		display: none;
	}
	a {
		@media #{$querySp} {
			position: relative;
			display: block;
			width: 45px;
			height: 45px;
			@include gradient(#7cc283, #37a3c7, vertical);
			overflow: hidden;
		}
		@media #{$queryPc} {
		}
		&:before {
			display: inline-block;
			content: "";
			position: absolute;
			top: 18px;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0 auto;
			width: 12px;
			height: 1px;
			background: #fff;
		}
		&:after {
			display: inline-block;
			content: "";
			position: absolute;
			top: 26px;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0 auto;
			width: 12px;
			height: 1px;
			background: #fff;
		}
		span {
			display: inline-block;
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			width: 12px;
			height: 1px;
			background: #fff;
		}
	}
}

.h-spNavWrap {
	@media #{$querySp} {
		@include gradient(#7cc283, #37a3c7, vertical);
		display: none;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		overflow-y: scroll;
  		-webkit-overflow-scrolling:touch;
		z-index: 2;
	}
	@media #{$queryPc} {
		flex: 1;
		overflow: hidden;
		zoom: 1;
	}
}

.h-bodyScrollNone {
	overflow: hidden;
	height: 100%;
}

.h-navHead {
	@media #{$querySp} {
		padding: 8px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	@media #{$queryPc} {
		display: none;
	}
}

.h-spHead__logo {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
	a {
		@media #{$querySp} {
			font-size: 2em;
		}
	}
	img {
		width: 120px;
		height: auto;
		margin-top: -6px;
	}
}

.h-navBtn__close a {
	@media #{$querySp} {
		position: relative;
		display: block;
		width: 45px;
		height: 45px;
		background-color: #fff;
		overflow: hidden;
	}
	@media #{$queryPc} {
	}
	&:before {
		display: inline-block;
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 1px;
		height: 10px;
		@include gradient(#7cc283, #37a3c7, vertical);
		transform: rotate(45deg);
	}
	&:after {
		display: inline-block;
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 1px;
		height: 10px;
		@include gradient(#7cc283, #37a3c7, vertical);
		transform: rotate(-45deg);
	}
}









































