@charset "UTF-8";
/* ----------------------------------------------------------------
余白のコンポーネット
-----------------------------------------------------------------*/
.c-spaceInner {
	@media #{$querySp} {
		margin-bottom: 40px;
	}
	@media #{$queryPc} {
		margin-bottom: 64px;
	}
}