@charset "UTF-8";
/* ----------------------------------------------------------------
the_content() を出力する親 div
-----------------------------------------------------------------*/
.wp-content {
@media #{$querySp} {
	margin-top: 10px;
}
@media #{$queryPc} {
}


h2,
h3,
h4,
h5 {
	color: $clrBlck;
}

h1,
h2,
h3 {
	font-family: $fontJpYuMin;
	color: #000;
	text-align: center;
	letter-spacing: 3px;
	@media #{$querySp} {
		margin-bottom: 24px;
		font-size: 1.4em;
	}
	@media #{$queryPc} {
		margin-bottom: 56px;
		font-size: 2.3rem;
	}
}

h4 {
	font-family: $fontJpYuMin;
	@media #{$querySp} {
		margin: 5px 0 5px;
		font-size: 1.2em;
	}
	@media #{$queryPc} {
		margin-bottom: 10px;
		line-height: 1.5;
		font-size: 1.8rem;
	}
}

h5 {
	font-weight: 600;
	@media #{$querySp} {
		font-size: 1.2rem;
		margin-bottom: 4px;
	}
	@media #{$queryPc} {
		margin-bottom: 2px;
		font-size: 1.6rem;
	}
}

p {
	@media #{$querySp} {
		margin-bottom: 20px;
	}
	@media #{$queryPc} {
		margin-bottom: 30px;
	}
}

a {
	text-decoration: underline;
	color: #38a3c6;
	&:hover {
		text-decoration: none;
		@include trans($duration: .1s,$delay: 0s);
		color: #55b0aa;
	}
}

img {
	@media #{$querySp} {
		margin: 10px 0;
		width: 100%;
		height: auto;
	}
	@media #{$queryPc} {
		margin: 20px 0;
	}
}


ol,
ul {
	@media #{$querySp} {
		margin: 16px 0;
		line-height: 1.3;
	}
	@media #{$queryPc} {
		margin: 24px 0;
		line-height: 1.5;
	}
}

//リスト
ul > li {
	padding-left: 16px;
	line-height: 1.5;
	margin-bottom: 12px;
}

ul > li {
	position: relative;
	&:before {
		display: block;
		content: '';
		position: absolute;
		top: 0.5em;
		left: 0;
		width: 9px;
		height: 5px;
		border-left: 2px solid #38a3c6;
		border-bottom: 2px solid #38a3c6;
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}
}

//番号リスト
ol > li {
	line-height: 1.5;
	margin-bottom: 12px;
}

ol {
	counter-reset: li;
}

ol > li {
	&:before {
		content:counter(li) ". ";
		counter-increment: li;
		font-weight: 600;
		color: #38a3c6;;
	}
}

hr {
	width: 100%;
	height: 1px;
	background-color: #ddd;
	@media #{$querySp} {
		margin: 16px 0;
	}
	@media #{$queryPc} {
		margin: 32px 0;
	}
}

strong {
	font-weight: 600;
}

.wp-caption.alignright {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		margin-left: 24px;
		float: right;
	}
}

.wp-caption.alignleft {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		margin-right: 24px;
		float: left;
	}
}

.wp-caption.aligncenter {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		margin: 24px auto;
	}
}

.wp-caption.alignright,
.wp-caption.alignleft,
.wp-caption.aligncenter {
	img {
		@media #{$querySp} {
			width: 100%;
			height: auto;
			margin-bottom: 0 !important;
		}
		@media #{$queryPc} {
			margin: 0 !important;
		}
	}
	.wp-caption-text {
		@media #{$querySp} {
			margin-bottom: 20px;
			font-size: 1.0rem;
		}
		@media #{$queryPc} {
			margin-bottom: 24px;
			font-size: 1.2rem;
		}
	}
}




}