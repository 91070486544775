@charset "UTF-8";
/* ----------------------------------------------------------------
SERVICEページのみのスタイル
-----------------------------------------------------------------*/
.p-xxxxxxxx {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-svListWrap {
	overflow: hidden;
	zoom: 1;
	@media #{$querySp} {
		margin-bottom: 20px;
		padding: 20px 0 20px;
	}
	@media #{$queryPc} {
		padding-top: 113px;
		margin-bottom: 60px;
	}
	&:last-child {
		@media #{$querySp} {
			margin-bottom: 0;
		}
	}
}

.p-svListWrap--01 {
	@media #{$querySp} {
		background: url("../images/bg_photoSv01.jpg") no-repeat center top;
		background-size: cover;
	}
	@media #{$queryPc} {
		background: url("../images/bg_photoSv01.jpg") no-repeat left top;
	}
}

.p-svListWrap--02 {
	@media #{$querySp} {
		background: url("../images/bg_photoSv02.jpg") no-repeat center top;
		background-size: cover;
	}
	@media #{$queryPc} {
		background: url("../images/bg_photoSv02.jpg") no-repeat right top;
	}
}

.p-svListWrap--03 {
	@media #{$querySp} {
		background: url("../images/bg_photoSv03.jpg") no-repeat center top;
		background-size: cover;
	}
	@media #{$queryPc} {
		background: url("../images/bg_photoSv03.jpg") no-repeat left top;
	}
}

.p-svListBox {
	@media #{$querySp} {
		width: 90%;
		margin: 0 auto;
		padding: 20px 10px;
		background-color: rgba(255,255,255,.8);
		text-align: center;
	}
	@media #{$queryPc} {
		position: relative;
		width: 816px;
		padding: 64px 64px 64px 156px;
		background-color: #fff;
	}
}

.p-svListBox--01, .p-svListBox--03 {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		float: right;
	}
}

.p-svListBox--02 {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		float: left;
	}
}

.p-svListHeadline {
	font-family: $fontJpHiraMin;
	color: #000;
	@media #{$querySp} {
		display: inline-block;
		position: relative;
		padding-left: 20px;
		font-size: 1.5rem;
		margin-bottom: 8px;
		text-align: center;
	}
	@media #{$queryPc} {
		margin-bottom: 16px;
		font-size: 3.0rem;
	}
	span {
		position: absolute;
		font-family: $fontEnCinzel;
		color: #7cc283;
		@include gradient(#7cc283, #37a3c7, horizontal);
	  	-webkit-background-clip: text;
	  	-webkit-text-fill-color: transparent;
		line-height: 1;
		@media #{$querySp} {
			top: -1px;
			left: 0;
			font-size: 2.5rem;
		}
		@media #{$queryPc} {
			top: 52px;
			left: 60px;
			font-size: 12.8rem;
		}
	}
}

.p-svListText {
	line-height: 2.5;
	text-align: left;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}