@charset "UTF-8";
/* ----------------------------------------------------------------
generalのスタイル
-----------------------------------------------------------------*/

/* 下層KV
-----------------------------------------------------------------*/
.g-hero {
	position: relative;
	z-index: 1;
	display: table;
	width: 100%;
	text-align: center;
	@media #{$querySp} {
		background: url("../images/bg_hero_pc.jpg") no-repeat center top;
		background-size: cover;
		height: 80px;
	}
	@media #{$queryPc} {
		background: url("../images/bg_hero_pc.jpg") no-repeat center top;
		background-size: cover;
		height: 187px;
	}
}

.g-heroInner {
	display: table-cell;
	vertical-align: middle;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}