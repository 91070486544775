@charset "UTF-8";
/* ----------------------------------------------------------------
テキストのコンポーネット
-----------------------------------------------------------------*/
.c-textBase {
	@media #{$querySp} {
		margin-bottom: 8px;
	}
	@media #{$queryPc} {
		font-size: 1.5rem;
		margin: 16px 0;
	}
}

.c-textPrice {
	color: #1c60a7;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}