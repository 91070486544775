@charset "UTF-8";
/* ----------------------------------------------------------------
footerのスタイル
-----------------------------------------------------------------*/
.f-topContentWrap {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: flex;
	}
}

.f-topContentBox {
	position: relative;
	overflow: hidden;
	@media #{$querySp} {
		width: 100%;
	}
	@media #{$queryPc} {
		width: 50%;
		height: 300px;
	}
}

.f-topContentBox:after {
	position: absolute;
	content: "";
	display: block;
	width: 100%;
	height: 100%;
	top: 0;
	@include trans($duration: .3s,$delay: 0s);
}

.f-topContentBox:hover:after {
	@include scale(1.1);
}

.f-topContentBox__about:after {
	@media #{$querySp} {
		background: url("../images/bg_footabout.jpg") no-repeat center center;
		background-size: cover;
	}
	@media #{$queryPc} {
		background: url("../images/bg_footabout.jpg") no-repeat center center;
		background-size: cover;
	}
}

.f-topContentBox__member:after {
	@media #{$querySp} {
		background: url("../images/bg_footmember.jpg") no-repeat center center;
		background-size: cover;
	}
	@media #{$queryPc} {
		background: url("../images/bg_footmember.jpg") no-repeat center center;
		background-size: cover;
	}
}

.f-topContentItem {
	position: relative;
	display: table;
	width: 100%;
	z-index: 1;
	@media #{$querySp} {
		height: 150px;
	}
	@media #{$queryPc} {
		height: 300px;
	}
}

.f-topContentItem__link {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	font-family: $fontEnCinzel;
	letter-spacing: 3px;
	color: #fff;
	text-shadow: 0px 0px 35px #000;
	@media #{$querySp} {
		font-size: 1.6rem;
	}
	@media #{$queryPc} {
		font-size: 2.6rem;
	}
	&:hover {
		color: #fff;
	}
}

.f-wrap {
	position: relative;
	z-index: 1;
	width: 100%;
	background-color: #fafafa;
	@media #{$querySp} {
		padding: 40px 20px 16px;
	}
	@media #{$queryPc} {
		padding: 45px 0;
	}
}

.f-wrapInner {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		width: 1260px;
		margin: 0 auto;
	}
}

.f-mainContents {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: flex;
		align-items: center;
		margin-bottom: 56px;
	}
}

.f-logo {
	@media #{$querySp} {
		margin-bottom: 16px;
		text-align: center;
		img {
			width: 120px;
			height: auto;
		}
	}
	@media #{$queryPc} {
		margin: -22px 62px 0 0;
	}
}

.f-contents__nav {
	@media #{$querySp} {
		display: none;
	}
}

.f-navWrap {
	@media #{$querySp} {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 30px;
		border-top: 1px solid #92b1c1;
	}
	@media #{$queryPc} {
		letter-spacing: -1em;
		text-align: right;
	}
}

.f-navItem {
	font-family: $fontJpYuGo;
	@media #{$querySp} {
		width: 50%;
		border-bottom: 1px solid #92b1c1;
		border-right: 1px solid #92b1c1;
		&:nth-child(odd) {
			border-left: 1px solid #92b1c1;
		}
		&:nth-child(3), &:nth-child(4) {
		}
	}
	@media #{$queryPc} {
		letter-spacing: 0;
		display: inline-block;
		margin-right: 40px;
	}
	&:last-child {
		@media #{$querySp} {
			border: none;
		}
		@media #{$queryPc} {
			margin-right: 0;
		}
		.f-navItem__link {
			display: inline;
			span {
				display: inline-block;
				padding-right: 8px;
			}
		}
	}
}

.f-navItem__link {
	@media #{$querySp} {
		display: block;
		padding: 14px 8px;
		font-size: 1.0rem;
		line-height: 1;
		span {
			position: relative;
			display: block;
			&:after {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				margin: auto 0;
				font-family: FontAwesome;
				content : "\f105";
			}
		}
	}
	@media #{$queryPc} {
		font-size: 1.2rem;
	}
	&:hover {
		@include opacity(0.7)
		@include trans($duration: .1s,$delay: 0s)
		@media #{$queryPc} {
		}
		@media #{$queryPc} {
		}
	}
}

.f-contents__sns {
	@media #{$querySp} {
		margin-bottom: 40px;
	}
	@media #{$queryPc} {
		margin-left: auto;
	}
}

.f-snsWrap {
	letter-spacing: -1em;
	@media #{$querySp} {
		text-align: center;
	}
	@media #{$queryPc} {
		text-align: right;
	}
}

.f-snsItem {
	letter-spacing: 0;
	display: inline-block;
	@media #{$querySp} {
		margin: 0 10px;
	}
	@media #{$queryPc} {
		margin-left: 15px;
	}
	&:nth-child(1) {
		img {
			width: 32px;
			height: auto;
		}
	}
	&:nth-child(2) {
		img {
			width: 35px;
			height: auto;
		}
	}
	&:nth-child(3) {
		img {
			width: 32px;
			height: auto;
		}
	}
}

.f-snsItem__link {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			@include opacity(0.7)
			@include trans($duration: .2s,$delay: 0s)
		}
	}
}

.f-copy {
	color: #555;
	line-height: 1;
	@media #{$querySp} {
		text-align: center;
		font-size: 1.0rem;
	}
	@media #{$queryPc} {
		text-align: right;
		font-size: 1.1rem;
	}
}