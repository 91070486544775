@charset "UTF-8";
/* ----------------------------------------------------------------
_newsのコンポーネット
-----------------------------------------------------------------*/
.c-newsItemBox {
	width: 100%;
	@media #{$queryPc} {
		padding: 0 60px;
	}
}

.c-newsItem {
	width: 100%;
	border-top: 1px solid $clrBorder;
	&:last-child {
		border-bottom: 1px solid $clrBorder;
	}
	/*&:nth-child(even) {
		background-color: #fafafa;
	}*/
	@media #{$querySp} {
		padding: 12px 10px;
	}
	@media #{$queryPc} {
		display: table;
		padding: 15px;
	}
}

.c-newsItem__date {
	color: #555;
	@media #{$querySp} {
		display: inline-block;
		margin-right: 8px;
		vertical-align: bottom;
	}
	@media #{$queryPc} {
		display: table-cell;
		padding-top: 2px;
		vertical-align: top;
		font-size: 1.2rem;
	}
}

.c-newsItem__cat {
	span {
		display: block;
		@include gradient(#7cc283, #37a3c7, horizontal);
		text-align: center;
		color: #fff;
			&:hover {
				@include opacity(1);
				@include trans($duration: .2s,$delay: 0s);
			}
	}
	@media #{$querySp} {
		display: inline-block;
		span {
			padding: 2px 12px;
			font-size: 1.0rem;
		}
	}
	@media #{$queryPc} {
		display: table-cell;
		width: 118px;
		padding: 0 30px 0 18px;
		span {
			display: inline-block;
			width: 56px;
			padding: 0 8px;
			font-size: 1.0rem;
		}
	}
}

.c-newsItem__cat--report {
	span {
		@include gradient(#c27cb1, #37a3c7, horizontal);
	}
}

.c-newsItem__headline {
	color: #000;
	@media #{$querySp} {
		display: block;
		margin-top: 8px;
	}
	@media #{$queryPc} {
		display: table-cell;
		width: 100%;
		padding-left: 16px;
	}
	a, span {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		margin: 0;
		text-decoration: none;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			line-height: 1.7;
		}
	}
	a {
		color: #38a3c6;
		&:hover {
			color: #55b0aa;
			@include trans($duration: .1s,$delay: 0s);
		}
	}
}