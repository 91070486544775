@charset "UTF-8";
/* ----------------------------------------------------------------
NEWS詳細ページのみのスタイル
-----------------------------------------------------------------*/
.p-newsHead {
	width: 100%;
	border-top: 1px solid $clrBorder;
	overflow: hidden;
	zoom: 1;
	color: #555;
	@media #{$querySp} {
		margin-bottom: 10px;
		padding: 2px 0;
	}
	@media #{$queryPc} {
		margin-bottom: 20px;
		padding-top: 4px;
	}
}

.p-newsHead__date {
	float: left;
}

.p-newsHead__dateItem {
	display: inline-block;
	vertical-align: middle;
	@media #{$querySp} {
		&:after {
			margin-left: 14px;
			height: 13px;
		}

	}
	@media #{$queryPc} {
		&:after {
			margin-left: 19px;
			height: 16px;
		}
	}
}

.p-newsPhotoWrap {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		overflow: hidden;
		zoom: 1;
	}
}

.p-newsPhoto-r {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		margin-left: 24px;
		float: right;
	}
}

.p-newsPhoto-l {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		margin-right: 24px;
		float: left;
	}
}

.p-newsPhoto-r,
.p-newsPhoto-l {
	img {
		@media #{$querySp} {
			margin-bottom: 0 !important;
		}
		@media #{$queryPc} {
			margin: 0 !important;
		}
	}
	figcaption {
		@media #{$querySp} {
			margin-bottom: 20px;
			font-size: 1.0rem;
		}
		@media #{$queryPc} {
			margin-bottom: 24px;
			font-size: 1.2rem;
		}
	}
}

.p-news2col {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: flex;
	}
}
